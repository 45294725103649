/* eslint-disable max-len */
import img3 from 'assets/images/concept3.jpg'

export const JPConcept = {
  header: {
    img: img3,
  },
  body: [
    {
      heading: 'ようこそ、OpenHouse2020へ',
      content: [
        '「技術の強みを拡大し、さらに迅速に事業に繋げる」べく、「研究・開発ユニット」は設立されました。',
        '新ユニットでは、競争力の源泉となるコア技術研究はより拡大しつつ、全社で保有する強み技術のシナジー創造を新たにスタート、',
        '実用化を加速するための開発、業界をリードする顧客との大型共創など、新しい取り組みに挑戦しています。',
      ],
    },
    {
      content: [
        'この4月の船出は、COVID-19への対応から始まりました。',
        'さまざまな困難に直面しつつも、新たな挑戦を続け、NECの変革をリードする新生「研究・開発ユニット」の姿をご覧ください。',
      ],
    },
    {
      heading: 'OpenHouseは、リアル から “オンライン共創の場” へ',
      content: [
        '今年のチャレンジとしてOpenHouseを「リアル」から「オンライン」へ移しました。',
        '社会は今まさに大きく変わりつつあります。このOpenHouseも、New Normalの社会で新しいコミュニケーションモデルを創り出していく我々のチャレンジのひとつです。',
        'オンラインの強みを活かし、集まる・深まる・繋がる、をコンセプトにDigital×Designで新たな共創の場づくりに取り組んでいます。',
      ],
    },
    {
      content: [
        '世界中のNECグループの皆さんと［時間］と［空間］を越えた議論や意見交換を行い、次の［共創］を生み出す場となることを願っています。',
      ],
    },
  ],
  footer: {
    content: ['NEC 執行役員常務 兼CTO', '西原 基夫'],
  },
}

export const ENConcept = {
  header: {
    img: img3,
  },
  body: [
    {
      heading: 'Welcome to OpenHouse2020',
      content: [
        'The Research & Development Unit was established to expand our technological strengths and to more rapidly develop business applications for them.',
        'This new unit seeks to embark on new endeavors and its purpose is broadly twofold. The first is to expand the pool of core technologies that make our company competitive while simultaneously attempting to create new synergies using our technological strengths. The second is to develop tools to accelerate the process of finding practical applications for our technologies, and to embark on large-scale co-creation ventures with our industry-leading clients.',
        'As we embarked on our journey in April, our first task was to adapt to COVID-19.',
        'The newly created Research & Development Unit has faced many trials and continues to embark on new endeavors as it serves as the vanguard of change for NEC. We hope you’ll join us on our journey to gain a better understanding of this new unit.',
      ],
    },
    {
      heading: 'The previously physical OpenHouse is now an online space for co-creation.',
      content: [
        'One of the challenges we have faced this year has been to shift OpenHouse from the physical realm to the online realm.',
        'Society is currently in the process of undergoing significant change. As society adjusts to the New Normal, one of our many endeavors has been to create a new communication model. OpenHouse is one such model.',
        'Through Digital x Design and by utilizing the strengths of the online realm, we have sought to create a new space for co-creation based on the concept of “Come Together/Deepen/Connect”.',
      ],
    },
    {
      content: [
        'By removing the barriers of [space] and [time], we hope OpenHouse will serve as a space where members of the NEC group from all over the world can debate and exchange opinions, and in doing so, lead to the birth of the next [co-creation].',
      ],
    },
  ],
  footer: {
    content: ['Motoo Nishihara', 'Executive Vice President, CTO'],
  },
}
