import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AppHeading from 'components/AppHeading'
import { Container } from '@material-ui/core'
import SEO from 'components/Seo'
import { pageView } from 'utils/tracker'
import { TRACKING, LANG_JP } from 'config/constants/common'
import { JPConcept, ENConcept } from 'config/constants/concept'
import './index.scss'

export default function Concept() {
  const { t, i18n } = useTranslation('concept')
  const { language: currentLang } = i18n

  const [conceptContent, setConceptContent] = useState(
    currentLang === LANG_JP ? JPConcept : ENConcept
  )

  useEffect(() => {
    pageView(trackingEventSource)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setConceptContent(currentLang === LANG_JP ? JPConcept : ENConcept)
  }, [currentLang])

  const trackingEventSource = TRACKING.SOURCE.CONCEPT

  return (
    <div id="concept-view" className={'Layout-children'}>
      <SEO title={t('lbl_title_en')} />
      <AppHeading title={t('lbl_title_en')} />

      <Container className="concept-content">
        {/* <p>
            <img
              src={JPConcept.header.img}
              alt={JPConcept.header.img}
              style={{ width: '100%', height: 'auto' }}
            />
          </p> */}
        {conceptContent.body.map((bodyPart, index) => (
          <div key={'JPConcept.body.' + index} className="JPConcept-body">
            {bodyPart.heading && (
              <p className="text-header" style={{ fontWeight: 'bold' }}>
                {bodyPart.heading}
              </p>
            )}
            <p
              className="text-paragraph JPConcept-text-paragraph"
              dangerouslySetInnerHTML={{ __html: bodyPart.content.join('\n') }}
            />
          </div>
        ))}
        <p
          className="text-paragraph JPConcept-text-paragraph JPConcept-footer"
          dangerouslySetInnerHTML={{ __html: conceptContent.footer.content.join('\n') }}
        />
      </Container>
    </div>
  )
}
